:root {
  --clamp: 4;
}

@media (max-width: 500px) {
    .MuiDrawer-paper.closed {
        width: 20px !important;
    }
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.product-title {
  /* color: black; */
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: var(--clamp);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-title:hover {
  text-decoration: underline;
  /* color: #555; */
}

.news-title {
  display: -webkit-box;
  -webkit-line-clamp: var(--clamp);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#main {
  overflow-x: hidden;
}

.my-app-code {
  text-decoration: none !important;
  font-size: 2em;
  /* color: #555 !important; */
}

a, a:visited {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
